<template>
  <div id="TechnologicalProcess">
    <!-- 流程 -->
    <Spot
      style="margin-top: 132px; margin-bottom: 78px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="content_box">
      <div class="left_box">
        <div class="back_box"></div>
        <div class="img_box">
          <img
            class="my_cover"
            src="../../../assets/img/TechnologicalProcess_left_img.png"
            alt=""
          />
        </div>
      </div>
      <div class="right_box">
        <div class="lang_line"></div>
        <div class="lang_line1"></div>
        <div
          class="right_item_box"
          v-for="(item, index) in process"
          :key="index"
        >
          <div class="dashed_radius">
            <div class="radius">{{ index + 1 }}</div>
          </div>
          <div class="square_box">
            <div class="title">{{ item.title }}</div>
            <div class="center_line"></div>
            <div class="info">{{ item.info }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      SpotEnTitle: "经销商加盟流程",
      SpotZhTitle: "Franchising process",
      process: [
        {
          title: "申请加盟",
          info: "选择加盟品牌及产品，与该地区品牌招商负责人联系申请",
        },
        {
          title: "审核资格",
          info:
            "招商联系人将对加盟商进行个人信征、经济实力、运营能力等资质进行核查面谈签约",
        },
        {
          title: "面谈签约",
          info:
            "前期市查通过之后,加盟商莅临总部签定合作协议,并现场缴纳加盟费用",
        },
        {
          title: "店面设计",
          info: "八益专业设计团队将针对店面进行空间、软装以及现场陈列设计",
        },
        {
          title: "开业庆典",
          info: "公司协助策划开业庆典活动,提供现场广宣设计等支持",
        },
      ],
    };
  },
};
</script>

<style scoped lang='scss'>
#TechnologicalProcess {
  width: 100%;
  background-image: url("../../../assets/img/jiining_form_back.png");
  background-size: 722px 722px;
  background-position-y: 672px;
  background-repeat: no-repeat;
  padding-bottom: 101px;
  .content_box {
    width: 1200px;
    margin: 0 auto;
    height: 877px;
    display: flex;
    justify-content: space-between;
    .left_box {
      width: 481px;
      height: 847px;
      margin-top: 10px;
      position: relative;
      .back_box {
        width: 383px;
        height: 780px;
        background: rgba(33, 68, 57, 0.05);
        position: absolute;
        left: 0;
        top: 0;
      }
      .img_box {
        width: 458px;
        height: 815px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .right_box {
      width: 621px;
      height: 100%;
      margin-top: 27px;
      position: relative;
      .lang_line {
        position: absolute;
        left: 22px;
        top: 0;
        width: 1px;
        height: 91%;
        background: #225928;
      }
      .lang_line1 {
        position: absolute;
        left: 22px;
        top: 0;
        width: 1px;
        height: 23px;
        background: #225928;
        transform: translateY(-23px);
      }
      .lang_line1::before{
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #225928;
        transform: translateX(-4px);
      }
      .right_item_box:last-child {
        margin-bottom: 0;
      }
      .right_item_box {
        transition: all 0.5s linear;
        width: 100%;
        height: 114px;
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dashed_radius {
          width: 42px;
          height: 42px;
          border: 1px dashed #959595;
          display: flex;
          justify-content: center;
          border-radius: 50%;
          align-items: center;
          z-index: 9;
          .radius {
            width: 32px;
            height: 32px;
            background: #959595;
            border-radius: 50%;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 32px;
            color: #ffffff;
            text-align: center;
          }
        }

        .square_box {
          width: 534px;
          height: 114px;
          background: #ffffff;
          // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border: 1px solid #e9e9e9;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            width: 49px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 40px;
            color: #333333;
            white-space: wrap;
            margin-left: 30px;
          }
          .info {
            width: 362px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #888888;
            margin-right: 19px;
          }
          .center_line {
            width: 1px;
            height: 38px;
            background: #d2d2d2;
          }
        }
      }
      .right_item_box:hover {
        transition: all 0.5s linear;
        width: 100%;
        height: 114px;
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dashed_radius {
          width: 42px;
          height: 42px;
          border: 1px dashed #005A3F;
          display: flex;
          justify-content: center;
          border-radius: 50%;
          align-items: center;
          z-index: 9;
          .radius {
            width: 32px;
            height: 32px;
            background: #005A3F;
            border-radius: 50%;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 32px;
            color: #ffffff;
            text-align: center;
          }
        }

        .square_box {
          width: 534px;
          height: 114px;
          background: #ffffff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          // border: 1px solid #e9e9e9;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            width: 49px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 40px;
            color: #005a3f;
            white-space: wrap;
            margin-left: 30px;
          }
          .info {
            width: 362px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #005A3F;
            margin-right: 19px;
          }
          .center_line {
            width: 1px;
            height: 38px;
            background: #884A08;
          }
        }
      }
    }
  }
}
</style>