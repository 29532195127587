<template>
  <div id="Advantage">
    <!-- 优势 -->
    <Spot
      style="margin-top: 81px; margin-bottom: 45px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="html_box">
      <!-- <div class="center_box" v-html="objData.text"></div> -->
      <div class="center_box">
        <img :src="objData.header_image" class="my_cover" alt="" />
        <div class="header_text">{{ objData.header_text }}</div>
      </div>
    </div>
    <div class="image_boxsecond">
      <img :src="objData.image" class="my_cover" alt="" />
    </div>
<!--    <div class="modules_box">-->
<!--      <div class="title">八益睡眠系列·多种模式选择</div>-->
<!--      <div class="six_box">-->
<!--        <div-->
<!--          class="six_item"-->
<!--          :class="'six_item' + index"-->
<!--          v-for="(item, index) in mudles"-->
<!--          :key="index"-->
<!--        >-->
<!--          <div class="radius_box">-->
<!--            <img :class="'img' + index" :src="item.image" alt="" />-->
<!--          </div>-->
<!--          <div class="name">{{ item.name }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <Spot
      style="margin-top: 46px; margin-bottom: 60px"
      :SpotZhTitle="SpotZhTitle1"
      :SpotEnTitle="SpotEnTitle1"
    ></Spot>
    <div class="four_box">
      <div class="four_item" v-for="(item, index) in objData.mode" :key="index">
        <div class="image_right_box" v-if="index % 2 == 0">
          <div
            class="text_box"
            :class="'text_leftA' + index"
            :style="{ marginTop: index == 0 ? '' : '45px' }"
          >
            <div class="center_box">
              <div class="title">{{ item.title }}</div>
              <div class="info">{{ item.info }}</div>
            </div>
          </div>
          <div class="image_box" :class="'image_rightA' + index">
            <img :src="item.image" class="my_cover" alt="" />
          </div>
        </div>
        <div class="image_left_box" v-else>
          <div class="image_box" :class="'image_leftA' + index">
            <img :src="item.image" class="my_cover" alt="" />
          </div>
          <div class="text_box" :class="'text_rightA' + index">
            <div class="center_box">
              <div class="title">{{ item.title }}</div>
              <div class="info">{{ item.info }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBrandAdvantage } from "../../../http/api";
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      mudles: [
        {
          image: require("../../../assets/img/mudles_1.png"),
          name: "防打鼾模式",
        },
        {
          image: require("../../../assets/img/mudles_2.png"),
          name: "零重力模式",
        },
        {
          image: require("../../../assets/img/mudles_3.png"),
          name: " 学习模式",
        },
        {
          image: require("../../../assets/img/mudles_4.png"),
          name: "休闲模式",
        },
        {
          image: require("../../../assets/img/mudles_5.png"),
          name: "按摩模式",
        },
        {
          image: require("../../../assets/img/mudles_6.png"),
          name: "放松模式",
        },
      ],
      SpotZhTitle: "品牌优势",
      SpotEnTitle: "Brand advantage",
      SpotZhTitle1: "四大优势",
      SpotEnTitle1: "Four advantages",
      objData: {},
      topscroll: 0,
      text_leftA: 0,
      setinter: null,
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
    getBrandAdvantage().then((res) => {
      console.log(res);
      res.data.text.replace(/<img/g, '<img style="max-width:100%;"');
      this.objData = res.data;
    });
  },
  watch: {
    text_leftA(val) {
      if (val == 4) {
        clearInterval(this.setinter);
      }
    },
    topscroll(val) {
      // console.log(val);
      if (val > 617) {
        $("#Advantage .image_boxsecond").addClass("image_boxsecond");
      }
      if (val > 1626) {
        $("#Advantage .six_item0").addClass("six_item0a");
        $("#Advantage .six_item1").addClass("six_item1a");
        $("#Advantage .six_item2").addClass("six_item2a");
        $("#Advantage .six_item3").addClass("six_item3a");
        $("#Advantage .six_item4").addClass("six_item4a");
        $("#Advantage .six_item5").addClass("six_item5a");
      }
      if (val > 2029) {
        // text_rightA0a image_rightA0a
        this.setinter = setInterval(() => {
          if (this.text_leftA == 0) {
            $("#Advantage .four_item .text_leftA0").addClass("text_rightA0a");
            $("#Advantage .four_item .image_rightA0").addClass(
              "image_rightA0a"
            );
          }
          if (this.text_leftA == 1) {
            $("#Advantage .four_item .image_leftA1").addClass("text_rightA0a");
            $("#Advantage .four_item .text_rightA1").addClass("image_rightA0a");
          }
          if (this.text_leftA == 2) {
            $("#Advantage .four_item .text_leftA2").addClass("text_rightA0a");
            $("#Advantage .four_item .image_rightA2").addClass(
              "image_rightA0a"
            );
          }
          if (this.text_leftA == 3) {
            $("#Advantage .four_item .image_leftA3").addClass("text_rightA0a");
            $("#Advantage .four_item .text_rightA3").addClass("image_rightA0a");
          }
          this.text_leftA++;
        }, 500);
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#Advantage {
  width: 100%;
  background-image: url("../../../assets/img/Advantage_back_img.png");
  background-size: 1087px 750px;
  background-position-y: 1386px;
  background-repeat: no-repeat;
  padding-bottom: 175px;
  .html_box {
    width: 100%;
    // height: 531px;
    background: rgba(206, 223, 218, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    .center_box {
      width: 1200px;
      margin: 43px 0 30px 0;
      .header_text {
        margin-top: 30px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 40px;
        color: #333333;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
      }
    }
  }
  .image_boxsecond {
    width: 1200px;
    height: 816px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .modules_box {
    height: 366px;
    width: 1200px;
    margin: 0 auto;
    .title {
      text-align: center;
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9a7247;
      margin-bottom: 60px;
    }
    .six_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .six_item {
        width: 134px;
        height: 194px;
        .radius_box {
          width: 134px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 134px;
          background: #ffffff;
          border: 1px solid #c8a86a;
          border-radius: 50%;
          .img0 {
            display: block;
            width: 74.7px;
            height: 77.43px;
          }
          .img1 {
            display: block;
            width: 83.6px;
            height: 99.6px;
          }
          .img2 {
            display: block;
            width: 79.26px;
            height: 65.31px;
          }
          .img3 {
            display: block;
            width: 58.04px;
            height: 74.88px;
          }
          .img4 {
            display: block;
            width: 80px;
            height: 79.98px;
          }
          .img5 {
            display: block;
            width: 77.94px;
            height: 67px;
          }
        }
        .name {
          text-align: center;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: #333333;
          margin-top: 34px;
        }
      }
    }
  }
  .four_box {
    width: 1200px;
    margin: 0 auto;
    .four_item {
      width: 100%;
      height: 432px;

      .image_right_box {
        display: flex;
        .text_box {
          width: 600px;
          height: 100%;
          .center_box {
            width: 460px;
            margin-left: 70px;
            .title {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
              // margin-bottom: 50px;
              margin-bottom: 30px;
            }
            .info {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
              white-space: pre-wrap;
            }
          }
        }
        .image_box {
          width: 600px;
          height: 390px;
          overflow: hidden;
        }
      }
      .image_left_box {
        display: flex;
        .text_box {
          width: 600px;
          height: 100%;
          .center_box {
            width: 460px;
            margin-left: 70px;
            margin-top: 45px;
            .title {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
              margin-bottom: 30px;
            }
            .info {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 30px;
              color: #333333;
              white-space: pre-wrap;
            }
          }
        }
        .image_box {
          width: 600px;
          height: 390px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>