<template>
  <div id="Support">
    <!-- 支持 -->
    <Spot
      style="margin-top: 110px; margin-bottom: 39px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="support_box">
      <div class="text_left">
        <div class="title">6大支持</div>
        <div class="line"></div>
        <div class="info" v-html="objData.text"></div>
      </div>
      <div class="image_left">
        <img :src="objData.image" class="my_cover" alt="" />
      </div>
    </div>
    <Spot
      style="margin-top: 70px; margin-bottom: 90px"
      :SpotZhTitle="SpotZhTitle1"
      :SpotEnTitle="SpotEnTitle1"
    ></Spot>
    <div class="information_box">
      <div
        class="item_box"
        v-for="(item, index) in objData.contact"
        :key="index"
      >
        <div class="top_back_box"></div>
        <div class="title_box">{{ item.area }}</div>
        <div class="name_box">
          <img
            src="../../../assets/img/name_icon.png"
            style="
              margin-right: 29px;
              width: 24.36px;
              height: 24.28px;
              display: inline-block;
            "
            alt=""
          />
          联系人： {{ item.name }}
        </div>
        <div class="tel_box">
          <img
            src="../../../assets/img/tel_icon.png"
            style="
              margin-right: 29px;
              width: 23.34px;
              height: 23.35px;
              display: inline-block;
            "
            alt=""
          />
          联系电话： {{ item.tel }}
        </div>
        <div class="wx_box">
          <img
            src="../../../assets/img/wx_icon.png"
            style="
              margin-right: 29px;
              width: 32.67px;
              height: 26.44px;
              display: inline-block;
            "
            alt=""
          />
          微信号： {{ item.wechat }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
import { getFranchiseSupport } from "../../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "加盟支持",
      SpotEnTitle: "Franchise support",
      SpotZhTitle1: "加盟联系方式",
      SpotEnTitle1: "Contact information",
      objData: {},
    };
  },
  mounted() {
    getFranchiseSupport().then((res) => {
      this.objData = res.data;
    });
  },
};
</script>

<style scoped lang='scss'>
#Support {
  width: 100%;
  background-image: url("../../../assets/img/condition_word_baoc.png");
  background-size: 1331px 890px;
  background-position-y: -150px;
  background-repeat: no-repeat;
  .support_box {
    width: 1200px;
    height: 542px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    display: flex;
    .text_left {
      width: 665px;
      height: 100%;
      .title {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 42px;
        color: #333333;
        margin-left: 40px;
        margin-top: 28px;
      }
      .line {
        width: 108px;
        height: 2px;
        background: #225928;
        margin-top: 8px;
        margin-bottom: 31px;
        margin-left: 40px;
      }
      .info {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        margin-left: 40px;
        margin-right: 32px;
      }
    }
    .image_left {
      width: 535px;
      height: 100%;
    }
  }
  .information_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 74px;
    flex-wrap: wrap;
    .item_box:hover{
      border: 1px solid transparent;
       transition: all 0.3s linear;
      .top_back_box{
        background: #016545;
        transition: all 0.3s linear;
      }
    }
    .item_box {
      width: 580px;
      height: 258px;
      margin-bottom: 30px;
      border: 1px solid #d5d5d5;
       transition: all 0.3s linear;
      .top_back_box {
        width: 100%;
        height: 14px;
        margin-bottom: 8px;
        background: transparent;
         transition: all 0.3s linear;
      }
      .title_box {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 37px;
        color: #04523a;
        margin-left: 41px;
        margin-bottom: 35px;
      }
      .name_box {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #3a3939;
        margin-left: 41px;
        display: flex;
      }
      .tel_box {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #3a3939;
        margin: 26px 0;
        margin-left: 41px;
        display: flex;
      }
      .wx_box {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #3a3939;
        margin-left: 41px;
        display: flex;
      }
    }
  }
}
</style>