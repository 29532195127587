<template>
  <div id="BrandJoining">
    <Header
      id="Header"
      class="BrandJoining_Header"
      :background="background"
    ></Header>
    <div class="top_banner"  :style="{ backgroundImage: 'url(' + top_banner + ')' }"></div>
    <Crumbs :crumbs="crumbs" :crumbs_active="crumbs_active"></Crumbs>
    <Advantage v-if="crumbs_active == 0"></Advantage>
    <Support v-if="crumbs_active == 1"></Support>
    <Condition v-if="crumbs_active == 2"></Condition>
    <TechnologicalProcess v-if="crumbs_active == 3"></TechnologicalProcess>
    <JoinIn v-if="crumbs_active == 4"></JoinIn>
    <Cooperation v-if="crumbs_active == 5"></Cooperation>
  </div>
</template>

<script>
import Advantage from "./components/Advantage";
import Support from "./components/Support";
import Condition from "./components/Condition";
import TechnologicalProcess from "./components/TechnologicalProcess";
import JoinIn from "./components/JoinIn";
import Cooperation from "./components/Cooperation";
import Header from "../../components/Header";
import Crumbs from "../../components/crumbs";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Crumbs,
    Advantage,
    Support,
    Condition,
    TechnologicalProcess,
    JoinIn,
    Cooperation,
  },
  data() {
    return {
      top_banner:'',
      crumbs_active: 0,
      background: {
        "background-color": "transparent",
      },
      crumbs: [
        {
          name: "品牌优势",
        },
        {
          name: "加盟支持",
        },
        {
          name: "加盟条件",
        },
        {
          name: "加盟流程",
        },
        {
          name: "我要加盟",
        },
        {
          name: "企业酒店合作",
        },
      ],
    };
  },
   mounted() {
    this.crumbs_active = localStorage.getItem("crumbs_active")
      ? localStorage.getItem("crumbs_active")
      : 0;
      var obj = JSON.parse(localStorage.getItem('getBanner'))
      this.top_banner = obj[2].image
  },
  watch: {
    crumbs_active(val) {
      localStorage.setItem("crumbs_active", val);
    },
  },
};
</script>

<style scoped lang='scss'>
#BrandJoining {
  width: 100%;
  .BrandJoining_Header {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .top_banner {
    width: 100%;
    // height: 850px;
    height: 44.27vw;
    // background-image: url("../../assets/img/BrandJoining_top_banner.png");
    background-size: cover;
  }
}
</style>