<template>
  <div id="Condition">
    <!-- 条件 -->
    <Spot
      style="margin-top: 130px; margin-bottom: 39px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="condition">
      <div class="left_box">
        <div class="left_title">加盟商条件</div>
        <div class="left_line"></div>
        <div class="left_info">{{ objData.info_one }}</div>
      </div>
      <div class="right_box">
        <img class="my_cover" :src="objData.image_one" alt="" />
      </div>
    </div>
    <Spot
      style="margin-top: 100px; margin-bottom: 70px"
      :SpotZhTitle="SpotZhTitle1"
      :SpotEnTitle="SpotEnTitle1"
    ></Spot>
    <div class="image_left_box">
      <div class="left_imgc">
        <img class="my_cover" :src="objData.image_two" alt="" />
      </div>
      <div class="right_textc">
        <div class="info_min">{{ objData.info_two }}</div>
      </div>
    </div>
    <div class="image_right_box">
      <div class="left_textc">
        <div class="info_min">{{ objData.info_three }}</div>
      </div>
      <div class="right_imgc">
        <img class="my_cover" :src="objData.image_three" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
import { getFranchiseConditions } from "../../../http//api";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "加盟条件",
      SpotEnTitle: "Franchise conditions",
      SpotZhTitle1: "加盟商经营场所要求",
      SpotEnTitle1: "Requirements for franchisee's business place",
      objData: {},
      topscroll: 0,
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
    getFranchiseConditions().then((res) => {
      this.objData = res.data;
    });
  },
  watch: {
    topscroll(val) {
      if (val > 890) {
        $("#Condition .left_imgc").addClass("left_imgca");
        $("#Condition .right_imgc").addClass("right_imgca");
        $("#Condition .left_textc").addClass("left_imgca");
        $("#Condition .right_textc").addClass("right_imgca");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#Condition {
  width: 100%;
  background-image: url("../../../assets/img/condition_word_baoc.png"),
    url("../../../assets/img/jiining_form_back.png");
  background-size: 1331px 890px;
  background-position-y: -150px, 1175px;
  background-repeat: no-repeat;
  padding-bottom: 180px;
  .condition {
    width: 1200px;
    height: 454px;
    margin: 0 auto;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    display: flex;

    .left_box {
      width: 572px;
      height: 100%;
      background: #fff;
      .left_title {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 42px;
        color: #333333;
        margin-top: 78px;
        margin-left: 48px;
      }
      .left_line {
        width: 144px;
        height: 2px;
        background: #225928;
        margin-top: 8px;
        margin-left: 48px;
      }
      .left_info {
        width: 477px;
        margin-top: 53px;
        margin-left: 48px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 40px;
        color: #666666;
        white-space: pre-wrap;
      }
    }
    .right_box {
      width: 628px;
      height: 100%;
    }
  }
  .image_left_box {
    width: 100%;
    height: 334px;
    margin-bottom: 30px;
    display: flex;
    .left_imgc {
      // width: 1275px;
      width: 66.4vw;
      height: 100%;
    }
    .right_textc {
      // width: 645px;
      width: 33.59vw;
      height: 100%;
      .info_min {
        // width: 434px;
        width: 22.6vw;
        height: 270px;
        margin-top: 62px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        margin-left: 30px;
        white-space: pre-wrap;
        overflow: hidden;
      }
    }
  }
  .image_right_box {
    width: 100%;
    height: 334px;
    display: flex;
    .right_imgc {
      // width: 1275px;
      width: 66.4vw;
      height: 100%;
    }
    .left_textc {
      // width: 645px;
      width: 33.59vw;
      height: 100%;
      .info_min {
        // width: 434px;
        width: 22.6vw;
        height: 220px;
        margin-top: 112px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        margin-left: 110px;
        white-space: pre-wrap;
        overflow: hidden;
      }
    }
  }
}
</style>