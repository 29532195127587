<template>
  <div id="JoinIn">
    <!-- 加盟 -->
    <div class="top_spot">
      <Spot
        style="margin-top: 132px; margin-bottom: 52px"
        :SpotZhTitle="SpotZhTitle"
        :SpotEnTitle="SpotEnTitle"
      ></Spot>
    </div>
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        style="margin-top: 70px; margin-bottom: 60px"
        class="demo-ruleForm form_min_box"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="ruleForm.name"
            style="width: 407px; heihgt: 56px"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="ruleForm.tel"
            style="width: 407px; heihgt: 56px"
            placeholder="请输入电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理区域" prop="add">
          <el-input
            v-model="ruleForm.add"
            style="width: 407px; heihgt: 56px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="意向品牌" prop="intention">
          <el-input
            v-model="ruleForm.intention"
            style="width: 407px; heihgt: 56px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="info">
          <el-input
            type="textarea"
            v-model="ruleForm.info"
            style="heihgt: 186px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit" @click="submit">提交</div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
import { submitForm } from "../../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "加盟表单填写",
      SpotEnTitle: "Fill in the join in form",
      ruleForm: {
        name: "",
        tel: "",
        add: "",
        intention: "",
        info: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [{ required: true, message: "请输入电话", trigger: "blur" }],
        add: [{ required: true, message: "请输入区域", trigger: "blur" }],
        intention: [{ required: true, message: "请输入意向", trigger: "blur" }],
      },
    };
  },
  methods: {
    submit() {
      if (
        this.ruleForm.name != "" &&
        this.ruleForm.tel != "" &&
        this.ruleForm.add != "" &&
        this.ruleForm.intention != ""
      ) {
        if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.ruleForm.tel)) {
          this.$message.error("请填写正确电话号码");
          this.ruleForm.name = "";
          this.ruleForm.tel = "";
          this.ruleForm.add = "";
          this.ruleForm.info = "";
          this.ruleForm.intention = "";
        } else {
          submitForm({
            type: 2,
            name: this.ruleForm.name,
            tel: this.ruleForm.tel,
            area: this.ruleForm.add,
            remark: this.ruleForm.info,
            brand: this.ruleForm.intention,
          }).then((res) => {
            if (res.status === "SUCCESS") {
              this.$message.success("提交成功");
              this.ruleForm.name = "";
              this.ruleForm.tel = "";
              this.ruleForm.add = "";
              this.ruleForm.info = "";
              this.ruleForm.intention = "";
            }
          });
        }
      } else {
        this.$message.error("请填写带星号的全部信息");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#JoinIn {
  width: 100%;
  background-image: url("../../../assets/img/jiining_form_back.png");
  background-size: 853px 722px;
  background-position-y: 488px;
  background-repeat: no-repeat;
  padding-bottom: 130px;
  .top_spot {
    width: 1200px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 auto;
  }
  .form_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .submit {
    width: 182px;
    height: 46px;
    background: #225928;
    border-radius: 8px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 46px;
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
  }
}
</style>